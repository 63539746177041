<template>
  <Input
    v-model="characterPlaceholder"
    :class="['character-count', characterCount > characterWarningLimit ? 'character-count--warning' : 'character-count--success']"
    :label="$t(label)"
    disabled
  />

</template>

<script>
import Input from '@/components/form/inputs/Input.vue'

export default {
  name: 'InputCharacterCount',
  data () {
    return {
      characterWarningLimit: 120
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    labelFrom: {
      type: String,
      required: true
    },
    characterLimit: {
      type: Number,
      default: 170
    }
  },
  components: {
    Input
  },
  computed: {
    characterPlaceholder () {
      return `${this.characterCount} ${this.$t(this.labelFrom)} ${this.characterLimit}`
    },
    characterCount () {
      return Number(this.title.length) + Number(this.text.length)
    }
  }

}
</script>
<style lang="scss">
.character-count {
  &--warning {
    input {
      color: var(--danger);
    }
  }

  &--success {
    input {
      color: var(--success);
    }
  }
}
</style>
